import React, { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useInput,
  SaveButton,
  Toolbar,
  required,
  Create,
} from "react-admin";
import { Button, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { config } from "ace-builds";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-textmate";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

config.setModuleUrl(
  "ace/mode/json_worker",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/worker-json.js"
);

const useStyles = makeStyles({
  cancelButton: {
    marginLeft: "5px",
  },
  title: {
    width: "100%",
    marginTop: "5px",
  },
  label: {
    marginBottom: "10px",
    color: "#0000008A",
    margin: "0px",
  },
  input: {
    margin: "0px",
  },
});

const JsonInput = (props) => {
  // const {
  //   input: { onChange },
  //   meta: { touched, error },
  // } = useField(source);
  const [code, setCode] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const fs = useInput(props);
  const { isRequired } = useInput(props);
  // console.log(props);
  // console.log(fs);

  useEffect(() => {
    // console.log("fs.input.value");
    // console.log(fs.input.value);
    setCode(JSON.stringify(fs.input.value, null, "\t"));
    setIsChanged(false);
  }, [fs.input.value]);

  function onChange(newValue) {
    setCode(newValue);
    setIsChanged(true);
  }

  function saveToFs() {
    // console.log("code");
    // console.log(code);
    const parsed = JSON.parse(code);
    fs.input.onChange({ target: { value: parsed } });
  }

  return (
    <div style={{ position: "relative" }}>
      <AceEditor
        mode="json"
        theme="textmate"
        width="100%"
        fontSize="11pt"
        value={code}
        onChange={onChange}
        name="weird"
        required={isRequired}
      />
      {isChanged && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveToFs}
          style={{ position: "absolute", bottom: "16px", left: "63px" }}
        >
          Commit changes
        </Button>
      )}
    </div>
    // <ReactJson
    //   name={false}
    //   collapsed={true}
    //   displayDataTypes={false}
    //   enableClipboard={false}
    //   src={fs.input.value || {}}
    //   onEdit={(e) => fs.input.onChange(e.updated_src)}
    //   onDelete={(e) => fs.input.onChange(e.updated_src)}
    //   onAdd={(e) => fs.input.onChange(e.updated_src)}
    // />
  );
};

const LanguageToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton submitOnEnter={false} redirect="list" />
  </Toolbar>
);

const CreateLanguageTitle = () => {
  const classes = useStyles();
  return <h3 className={classes.title}>Add a new Language</h3>;
};

const DefinitionLabel = () => {
  const classes = useStyles();
  return <p className={classes.label}>Definition*</p>;
};

export const LanguageCreate = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const closePanel = () => {
    history.push("/languages");
  };

  const onFailure = (error) => {
    console.log(error.message);
    Swal.fire({
      title: "Error",
      text: error.message,
      icon: "warning",
      customClass: {
        container: "swal2-maxed",
      },
    });
  };
  return (
    <div style={{ padding: "16px" }}>
      <Button
        onClick={closePanel}
        variant="contained"
        color="secondary"
        startIcon={<CloseIcon />}
        size="small"
      >
        Close
      </Button>
      <Create title=" " {...props} onFailure={onFailure}>
        <SimpleForm
          toolbar={<LanguageToolbar />}
          redirect={false}
          submitOnEnter={false}
        >
          <CreateLanguageTitle />
          <TextInput
            source="namespace"
            label="Namespace"
            validate={[required()]}
            fullWidth
            className={classes.input}
          />
          <TextInput
            source="language"
            label="Language"
            fullWidth
            validate={[required()]}
            className={classes.input}
          />
          <TextInput
            source="description"
            label="Description"
            fullWidth
            className={classes.input}
          />
          <DefinitionLabel />
          <JsonInput
            onChange={(value) => console.log(value)}
            source="definition"
            label="Definition"
            validate={[required()]}
          />
        </SimpleForm>
      </Create>
    </div>
  );
};

const EditTitle = ({ record }) => {
  const classes = useStyles();

  return (
    <h3 className={classes.title}>
      {record ? `Edit ${record.namespace}` : ``}
    </h3>
  );
};

export const LanguageEdit = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const closePanel = () => {
    history.push("/languages");
  };
  console.log(props.match.params.id);
  const onFailure = (error) => {
    console.log(error.message);
    Swal.fire({
      title: "Error",
      text: error.message,
      icon: "warning",
      customClass: {
        container: "swal2-maxed",
      },
    });
  };
  return (
    <div style={{ padding: "16px" }}>
      <Button
        onClick={closePanel}
        variant="contained"
        color="secondary"
        startIcon={<CloseIcon />}
        size="small"
      >
        Close
      </Button>
      <Edit title=" " {...props} onFailure={onFailure}>
        <SimpleForm
          toolbar={<LanguageToolbar />}
          redirect={false}
          submitOnEnter={false}
        >
          <EditTitle className={classes.title} />
          <TextInput
            source="namespace"
            label="Namespace"
            validate={[required()]}
            fullWidth
            className={classes.input}
          />
          <TextInput
            source="language"
            label="Language"
            fullWidth
            validate={[required()]}
            className={classes.input}
          />
          <TextInput
            source="description"
            label="Description"
            fullWidth
            className={classes.input}
          />
          <DefinitionLabel />
          <JsonInput
            onChange={(value) => console.log(value)}
            source="definition"
            label="Definition"
            validate={[required()]}
          />
        </SimpleForm>
      </Edit>
    </div>
  );
};
