import Swal from "sweetalert2";
import { changePasswordApi } from "../provider/mbaasClient";

export async function changePasswordFlow() {
  const promptResult = await Swal.mixin({
    input: "password",
    confirmButtonText: "Continue &rarr;",
    showCancelButton: true,
    progressSteps: ["1", "2"],
    icon: "question"
  }).queue([
    {
      title: "Change Password",
      text: "Type your current password"
    },
    {
      title: "Change Password",
      text: "Type your new Password",
      preConfirm: nPass => {
        if (!nPass || nPass.length < 8) {
          Swal.showValidationMessage(
            `New password should have 8-characters long`
          );
          return false;
        }
      }
    }
  ]);

  // return if canceled
  if (!promptResult.value || promptResult.cancel) {
    return;
  }
  // console.log(promptResult);

  // confirm button
  const isConfirmed = await Swal.fire({
    title: "You will change your password?",
    text: "Please press YES to proceed",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes"
  }).then(result => {
    if (result.value) return true;
    return false;
  });
  //
  if (!isConfirmed) return;

  const updateResult = await changePasswordApi({
    oldPassword: promptResult.value[0],
    newPassword: promptResult.value[1]
  });

  if (updateResult && (updateResult.success || updateResult.error)) {
    Swal.fire("Change Password", updateResult.error || "", "info");
  } else {
    Swal.fire("Failed", "Unable to process your request", "error");
  }
  // console.log(updateResult);
}
