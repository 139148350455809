import memoize from "memoizee";
import { client } from "./mbaasClient";

let _email = null;
let _id = null;

const method = {
  // called when the user attempts to log in
  login: async ({ email, password }) => {
    const { error } = await client.auth.login("local", {
      email: email,
      password: password,
    });

    if (!error) {
      // accept all username/password combinations
      await method.memoizedUser(); // init memoization
      return Promise.resolve();
    } else {
      console.log(error);
      return Promise.reject();
    }
  },
  // called when the user clicks on the logout button
  logout: async () => {
    client.user.logout();
    await method.memoizedUser.clear(); // clear memoization
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: async () => {
    const { error } = await client.user.get();
    // console.log("checkError");
    // console.log(error);
    if (error && (error.status === "401" || error.status === "403")) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    const { error } = await method.memoizedUser();
    const isLoggedIn = client.user.isLoggedIn;
    // console.log("checkAuth");
    // console.log([error, isLoggedIn]);
    return isLoggedIn && !error ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => {
    const { data } = await method.memoizedUser();
    _email = data.email;
    _id = data.id;
    // console.log(data);
    return data ? Promise.resolve(data.roles) : Promise.reject();
  },
  email: () => _email,
  id: () => _id,
  data: () => client.user.get().then((result) => result.data || {}),
  memoizedUser: memoize(() => client.user.get(), {}),
};

export default method;
