import React from "react";
import {
  Filter,
  List,
  Datagrid,
  Create,
  TextField,
  SelectInput,
  PasswordInput,
  SelectArrayInput,
  DateField,
  EditButton,
  DeleteButton,
  Edit,
  SimpleForm,
  TextInput,
  FunctionField,
} from "react-admin";
import { Box } from "@material-ui/core";
import { CustomPaging } from "./customized/pagination";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const UserList = (props) => (
  <List
    title="User List"
    bulkActionButtons={false}
    filters={<UserFilter />}
    {...props}
    pagination={<CustomPaging />}
  >
    <Datagrid>
      <TextField source="email" label="E-mail" />
      <TextField source="name" label="Name" />
      <FunctionField
        label="Roles"
        render={(record) => <span>{(record.roles || []).join(",")}</span>}
      />
      <FunctionField
        label="Allowed IP"
        render={(record) => <span>{(record.allowed_ip || []).join(",")}</span>}
      />
      {/* <FunctionField
        label="Metadata"
        render={record => (
          <ReactJson
            name={false}
            collapsed={true}
            displayDataTypes={false}
            enableClipboard={false}
            src={record.metadata || {}}
          />
        )}
      /> */}
      <TextField source="supplier_code" label="Supplier code" />
      <DateField source="created_at" label="Created" showTime />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

const UserCreate = (props) => (
  <Create title="Add a new user" {...props}>
    <SimpleForm redirect={(basePath) => basePath}>
      <Box p="1em" fullWidth>
        <Box display="flex">
          <Box flex={2} mr="1em">
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput
                  source="name"
                  label="Nama"
                  fullWidth
                  data-cy="create-users-name"
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput
                  source="email"
                  label="E-mail"
                  fullWidth
                  data-cy="create-users-email"
                />
              </Box>
              <Box flex={1} ml="0.5em">
                <PasswordInput
                  source="password"
                  label="Password"
                  fullWidth
                  data-cy="create-users-password"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </SimpleForm>
  </Create>
);

const Name = ({ record }) => {
  return <span>{record ? `Edit ${record.name}` : ``}</span>;
};

const UserEdit = (props) => (
  <Edit title={<Name />} {...props}>
    <SimpleForm>
      <Box p="1em" fullWidth>
        <Box display="flex">
          <Box flex={2} mr="1em">
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput source="id" label="Id" disabled fullWidth />
              </Box>
              <Box flex={1} ml="0.5em">
                <SelectArrayInput
                  source="roles"
                  label="Roles"
                  data-cy="select-roles"
                  choices={[
                    { id: "Administrator", name: "Administrator" },
                    { id: "Staff", name: "Staff" },
                    { id: "Supplier", name: "Supplier" },
                    { id: "B2B_Partner", name: "B2B_Partner" },
                    { id: "Anonymous", name: "Anonymous" },
                  ]}
                  fullWidth
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput source="email" label="E-mail" fullWidth />
              </Box>
              <Box flex={1} ml="0.5em">
                <TextInput source="name" label="Name" fullWidth />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput
                  source="supplier_code"
                  label="Supplier code"
                  fullWidth
                  data-cy="supplier-code"
                />
              </Box>
            </Box>
            {/* <Box display="flex">
              <Box flex={1} ml="0.5em">
                <TextInput
                  source="social_ids"
                  label="Social ID"
                  parse={v => JSON.parse(v)}
                  format={v => JSON.stringify(v)}
                  fullWidth
                  multiline
                />
              </Box>
            </Box> */}
            <Box display="flex">
              <Box flex={1} ml="0.5em">
                <SelectInput
                  source="verified"
                  label="Verified"
                  data-cy="select-verified"
                  choices={[
                    { id: true, name: "True" },
                    { id: false, name: "False" },
                  ]}
                  fullWidth
                />
              </Box>
            </Box>
            <Box mt="1em" />
          </Box>
        </Box>
      </Box>
    </SimpleForm>
  </Edit>
);

export { UserList, UserCreate, UserEdit };
