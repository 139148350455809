import * as React from "react";
import { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import { useHistory } from "react-router-dom";
import {
  Card,
  Grid,
  Container,
  TextField,
  Button,
  InputAdornment,
} from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LockIcon from "@material-ui/icons/Lock";
import { makeStyles } from "@material-ui/core/styles";
import fascon from "../assets/fascon.png";
import Validator from "validatorjs";
import { client } from "../provider/mbaasClient";

import "rapidoc"; // <-- import rapidoc

const validationRules = {
  email: "required|email",
  password: "required|string",
};

const useStyles = makeStyles({
  loginContainer: {
    background: "white !important",
    marginTop: "32px !important",
    padding: "32px",
  },
  background: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: 0,
    left: 0,
    background:
      "linear-gradient(0deg, rgba(0,0,0,0.8575805322128851) 19%, rgba(255,0,0,1) 100%);",
    zIndex: -10,
  },
  form: {
    marginBottom: "16px",
  },
  gridImage: {
    textAlign: "center",
  },
  imageInGrid: {
    objectFit: "contain",
    maxWidth: "96px",
  },
});

const MyLoginPage = ({ theme }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [viewDocs, setViewDocs] = useState(false);
  const login = useLogin();
  const notify = useNotify();
  const history = useHistory();

  const submit = (e) => {
    e.preventDefault();
    const validation = new Validator({ email, password }, validationRules);
    if (validation.fails()) {
      return notify("Please type email or password correctly", "warning");
    }

    login({ email, password }).catch(() =>
      notify("Invalid email or password", "warning")
    );
  };

  const google = (e) => {
    e.preventDefault();
    client.auth.oauthRedirect(
      "google",
      window.location.origin + window.location.pathname
    );
  };

  const openApiDoc = (e) => {
    e.preventDefault();
    setViewDocs(true);
  };

  const thisUrl = new URL(window.location.href);
  const googleCode = thisUrl.searchParams.get("code");
  window.history.pushState(null, null, window.location.pathname);
  if (googleCode) {
    client.auth
      .login("google", {
        callback: window.location.origin + window.location.pathname,
        code: googleCode,
      })
      .then((res) => {
        console.log(res);
        if (res.error) {
          return notify("Invalid OAuth2 authorization", "warning");
        }
        if (res.data.type === "LoginExisting" && res.data.verified) {
          history.push("/");
        }
      });
  }

  return (
    <React.Fragment>
      <div className={classes.background}></div>
      {viewDocs ? (
        <B2bDocumentation />
      ) : (
        <Container maxWidth="xs">
          <Card className={classes.loginContainer} raised>
            <Grid container direction="column" spacing={2}>
              <Grid item className={classes.gridImage}>
                <img
                  src={fascon}
                  alt="fascon-logo"
                  className={classes.imageInGrid}
                />
              </Grid>
              <Grid item>
                <form
                  className={classes.form}
                  noValidate
                  autoComplete="off"
                  onSubmit={submit}
                >
                  <TextField
                    variant="standard"
                    label="Email"
                    fullWidth
                    data-cy="login-email"
                    margin="dense"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountBoxIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Password"
                    variant="standard"
                    type="password"
                    fullWidth
                    data-cy="login-password"
                    margin="dense"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  data-cy="login-submit-local"
                  color="primary"
                  onClick={submit}
                >
                  Login
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  onClick={google}
                >
                  Google Sign-In
                </Button>
              </Grid>

              <Grid item style={{ marginTop: 12 }}>
                <Button variant="contained" fullWidth onClick={openApiDoc}>
                  B2B API Documentation
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Container>
      )}
      <Notification />
    </React.Fragment>
  );
};

export default MyLoginPage;

function B2bDocumentation() {
  return (
    <rapi-doc
      allow-spec-file-load="false"
      show-header="false"
      spec-url="/fascon.v1.json"
      render-style="read"
      style={{ height: "100vh", width: "100%" }}
    ></rapi-doc>
  );
}
