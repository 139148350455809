import englishMessages from "ra-language-english";
import polyglotI18nProvider from "ra-i18n-polyglot";

const i18nMessages = {
  en: englishMessages,
};

export const i18nProvider = polyglotI18nProvider(
  (locale) => i18nMessages[locale],
  "en",
  { allowMissing: true }
);
