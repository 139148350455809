import React from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  TextInput,
  FunctionField,
  CreateButton,
  ExportButton,
  TopToolbar,
  AutocompleteInput,
} from "react-admin";
import ReactJson from "react-json-view";
import { CustomPaging } from "./customized/pagination";
import { Route } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import { LanguageEdit, LanguageCreate } from "./LanguageDrawer";

const LanguageFilter = (props) => (
  <Filter {...props}>
    <AutocompleteInput
      alwaysOn
      label="Search"
      source="language"
      choices={[
        { id: "id", name: "Indonesia" },
        { id: "en", name: "English" },
        { id: "ph", name: "Philiphines" },
        { id: "vi", name: "Vietnam" },
        { id: "th", name: "Thailand" },
        { id: "my", name: "Malaysia" },
      ]}
    />
  </Filter>
);

const LanguageListActions = ({ basePath, resource }) => {
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <ExportButton resource={resource} />
    </TopToolbar>
  );
};

const LanguageList = (props) => {
  return (
    <React.Fragment>
      <List
        title="Language List"
        bulkActionButtons={false}
        filters={<LanguageFilter />}
        {...props}
        pagination={<CustomPaging />}
        actions={<LanguageListActions />}
      >
        <Datagrid>
          <FunctionField
            source="language"
            label="Language"
            render={(record) => {
              switch (record.language) {
                case "id":
                  return <span>Indonesia</span>;
                case "en":
                  return <span>English</span>;
                case "ph":
                  return <span>Philiphines</span>;
                case "vi":
                  return <span>Vietnam</span>;
                case "th":
                  return <span>Thailand</span>;
                case "my":
                  return <span>Malaysia</span>;
                default:
                  return "-";
              }
            }}
          />
          <TextField source="namespace" label="Namespace" />
          <FunctionField
            label="Definition"
            render={(record) => (
              <ReactJson
                name={false}
                collapsed={true}
                displayDataTypes={false}
                enableClipboard={false}
                src={record.definition || {}}
              />
            )}
          />
          <DateField source="created_at" label="Created" showTime />
          <EditButton />
          <DeleteButton undoable={false} />
        </Datagrid>
      </List>
      <Route path="/languages/create">
        {({ match }) => {
          return (
            <Drawer open={!!match ? !!match : false} anchor="right">
              <LanguageCreate {...props} style={{ width: "50vw" }} />
            </Drawer>
          );
        }}
      </Route>
      <Route path="/languages/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open={isMatch ? isMatch : false} anchor="right">
              {isMatch ? (
                <LanguageEdit
                  {...props}
                  id={isMatch ? match.params.id : null}
                  style={{ width: "50vw" }}
                />
              ) : (
                <div />
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
};

export { LanguageList };
