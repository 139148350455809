import React from "react";
import { Layout } from "react-admin";
import customAppBar from "./appbar";
// import MySidebar from "./MySidebar";
import customMenu from "./menu";
// import MyNotification from "./MyNotification";

const MyLayout = props => (
  <Layout {...props} menu={customMenu} appBar={customAppBar} />
);

export default MyLayout;
