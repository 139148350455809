import React, { Suspense, lazy } from "react";
import { Admin, Resource } from "react-admin";

import LoadingIndicator from "./components/LoadingIndicator";
// icons
import CustomerIcon from "@material-ui/icons/Person";
import UserIcon from "@material-ui/icons/Group";
import BroadcastLogIcon from "@material-ui/icons/Send";
import ProductCategoryIcon from "@material-ui/icons/Folder";
import ProductItemIcon from "@material-ui/icons/Work";
import TransactionIcon from "@material-ui/icons/TrendingUp";
import LanguageIcon from "@material-ui/icons/Language";
import VoucherCategoryIcon from "@material-ui/icons/Payment";
import VoucherStockIcon from "@material-ui/icons/SimCard";
import WrapTextIcon from "@material-ui/icons/WrapText";
import DocumentIcon from "@material-ui/icons/Description";
import LoyaltyIcon from '@material-ui/icons/Loyalty';
// provider
import authProvider from "./provider/auth";
import dataProvider from "./provider/data";
// menu layout
import CustomLayout from "./layout";
import Menu from "./layout/menu";
import LoginPage from "./layout/login";
import { createBrowserHistory } from "history";
import customRoutes from "./customRoutes";
// main css
import "./App.css";
// componenets
import { UserList, UserCreate, UserEdit, LanguageList } from "./components";

import { i18nProvider } from "./provider/i18n";

const CustomerEdit = lazy(() => import("./components/customers/b2bEdit"));
const CustomerCreate = lazy(() => import("./components/customers/create"));
const CustomerB2bList = lazy(() => import("./components/customers/b2b"));
const CustomerB2cList = lazy(() => import("./components/customers/b2c"));

const Dashboard = lazy(() => import("./components/dashboard"));
const TransactionList = lazy(() => import("./components/TransactionList"));

const ProductCategoryList = lazy(() =>
  import("./components/ProductCategoryList")
);
const ProductCategoryCreate = lazy(() =>
  import("./components/ProductCategoryCreate")
);
const ProductCategoryEdit = lazy(() =>
  import("./components/ProductCategoryEdit")
);

const PromotionsList = lazy(() =>
  import("./components/PromotionsList")
);
const PromotionsCreate = lazy(() =>
  import("./components/PromotionsCreate")
);
const PromotionsEdit = lazy(() =>
  import("./components/PromotionsEdit")
);

const PromotionsCodeList = lazy(() =>
  import("./components/PromotionsCodeList")
);
const PromotionsCodeCreate = lazy(() =>
  import("./components/PromotionsCodeCreate")
);
const PromotionsCodeEdit = lazy(() =>
  import("./components/PromotionsCodeEdit")
);

const BatchList = lazy(() => import("./components/BatchList"));

const ProductItemCreate = lazy(() => import("./components/ProductItemCreate"));
const ProductItemEdit = lazy(() => import("./components/ProductItemEdit"));
const ProductItemList = lazy(() => import("./components/ProductItemList"));
const ExecutionLogList = lazy(() => import("./components/ExecutionLogList"));

const VoucherCategoryCreate = lazy(() =>
  import("./components/VoucherCategoryCreate")
);
const VoucherCategoryEdit = lazy(() =>
  import("./components/VoucherCategoryEdit")
);
const VoucherCategoryList = lazy(() =>
  import("./components/VoucherCategoryList")
);

const VoucherStockList = lazy(() => import("./components/VoucherStockList"));
const VoucherStockEdit = lazy(() => import("./components/VoucherStockEdit"));

const SupplyRouting = lazy(() => import("./components/SupplyRouting"));
const SupplyMapList = lazy(() => import("./components/SupplyMapList"));
const SupplyMapEdit = lazy(() => import("./components/SupplyMapEdit"));
const SupplyMapCreate = lazy(() => import("./components/SupplyMapCreate"));
const NotFound = lazy(() => import("./components/NotFound"));

function validatePermission(arr1, ref) {
  const arr2 = typeof ref === "string" ? [ref] : ref;
  const intersectArr = arr1.filter((x) => arr2.includes(x));
  return intersectArr.length > 0 ? true : false;
}

const BroadcastLogList = lazy(() => import("./components/BroadcastLogList"));
const BroadcastLogCreate = lazy(() =>
  import("./components/BroadcastLogCreate")
);

const history = createBrowserHistory();

const App = () => {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Admin
        i18nProvider={i18nProvider}
        catchAll={NotFound}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
        loginPage={LoginPage}
        layout={CustomLayout}
        menu={Menu}
        history={history}
        customRoutes={customRoutes}
      >
        {(permissions) => [
          validatePermission(permissions, ["Administrator", "Staff"]) ? (
            <Resource
              icon={CustomerIcon}
              name="customers"
              options={{ label: "Customers" }}
              list={CustomerB2cList}
            />
          ) : null,
          validatePermission(permissions, ["Administrator", "Staff"]) ? (
            <Resource
              icon={CustomerIcon}
              name="customers-b2b"
              options={{ label: "CustomersB2b" }}
              list={CustomerB2bList}
              create={CustomerCreate}
              edit={CustomerEdit}
            />
          ) : null,
          validatePermission(permissions, ["Administrator"]) ? (
            <Resource
              icon={UserIcon}
              name="users"
              options={{ label: "Users" }}
              list={UserList}
              edit={UserEdit}
              create={UserCreate}
            />
          ) : null,
          validatePermission(permissions, ["Administrator"]) ? (
            <Resource
              icon={BroadcastLogIcon}
              name="broadcast_logs"
              options={{ label: "Broadcast logs" }}
              list={BroadcastLogList}
              create={BroadcastLogCreate}
            />
          ) : null,
          validatePermission(permissions, ["Administrator", "Staff"]) ? (
            <Resource
              icon={ProductCategoryIcon}
              name="product_categories"
              options={{ label: "Product categories" }}
              list={ProductCategoryList}
              edit={ProductCategoryEdit}
              create={ProductCategoryCreate}
            />
          ) : null,
          validatePermission(permissions, ["Administrator", "Staff"]) ? (
            <Resource
              icon={ProductItemIcon}
              name="product_items"
              options={{ label: "Product items" }}
              list={ProductItemList}
              edit={ProductItemEdit}
              create={ProductItemCreate}
            />
          ) : null,

          validatePermission(permissions, ["Administrator"]) ? (
            <Resource
              icon={LoyaltyIcon}
              name="promotions"
              options={{ label: "Banner Promotions" }}
              list={PromotionsList}
              edit={PromotionsEdit}
              create={PromotionsCreate}
            />
          ) : null,
          validatePermission(permissions, ["Administrator"]) ? (
            <Resource
              icon={LoyaltyIcon}
              name="promotion_codes"
              options={{ label: "Promotion Code" }}
              list={PromotionsCodeList}
              edit={PromotionsCodeEdit}
              create={PromotionsCodeCreate}
            />
          ) : null,
          validatePermission(permissions, ["Administrator", "Staff"]) ? (
            <Resource
              icon={TransactionIcon}
              name="transactions"
              options={{ label: "Transactions" }}
              list={TransactionList}
            />
          ) : null,
          permissions.includes("Administrator") ? (
            <Resource
              icon={DocumentIcon}
              name="documents"
              options={{ label: "Batch" }}
              list={BatchList}
            />
          ) : null,
          permissions.includes("Administrator") ? (
            <Resource
              icon={LanguageIcon}
              name="languages"
              options={{ label: "Languages" }}
              list={LanguageList}
            />
          ) : null,
          validatePermission(permissions, ["Administrator", "Staff"]) ? (
            <Resource
              icon={VoucherCategoryIcon}
              name="voucher_categories"
              options={{ label: "Voucher categories" }}
              list={VoucherCategoryList}
              edit={VoucherCategoryEdit}
              create={VoucherCategoryCreate}
            />
          ) : null,
          validatePermission(permissions, ["Administrator", "Staff"]) ? (
            <Resource
              icon={VoucherStockIcon}
              name="voucher_stocks"
              options={{ label: "Voucher stocks" }}
              list={VoucherStockList}
              edit={VoucherStockEdit}
            />
          ) : null,
          validatePermission(permissions, ["Administrator", "Staff"]) ? (
            <Resource
              icon={WrapTextIcon}
              name="execution_logs"
              options={{ label: "Execution Logs" }}
              list={ExecutionLogList}
            />
          ) : null,
          permissions.includes("Administrator") ? (
            <Resource
              name="supply_maps"
              options={{ label: "Supplies" }}
              list={SupplyMapList}
              create={SupplyMapCreate}
              edit={SupplyMapEdit}
            />
          ) : null,
          permissions.includes("Administrator") ? (
            <Resource
              name="routing_rules"
              options={{ label: "Supply Routing" }}
              list={SupplyRouting}
            />
          ) : null,
          <Resource name="ledger_accounts" />,
        ]}
      </Admin>
    </Suspense>
  );
};

export default App;
