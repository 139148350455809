import { URL } from "./config";
import axios from "axios";
export const moco = require("@mocobaas/client-js");
export const client = new moco.init(URL, {
  payloadEncryption: {
    enforcement: ["COMMON", "TABLE", "GRAPHQL", "FUNCTION"],
  },
});
export const appURL = URL;

export async function getDashboard() {
  try {
    const { data, error } = await client.invokeMethod("dashboard-stats");
    if (error) throw new Error(error);
    return data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export async function generateAuthenticatorCode(password) {
  try {
    const { data, error } = await client.invokeMethod(
      "regenerate-totp-secret",
      { password }
    );
    if (error) throw new Error(error);
    return data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export async function overrideTransaction(requestData) {
  try {
    const { data, error } = await client.invokeMethod(
      "override-transaction",
      requestData
    );
    if (error) throw new Error(error);
    return data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export async function askPgCallback(requestData) {
  try {
    const { data, error } = await client.invokeMethod(
      "ask-pgw-callback",
      requestData
    );
    if (error) throw new Error(error);
    return data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export async function uploadCsvFile(fname, fileObj) {
  try {
    const { data: uploadUrl, error } = await client.invokeMethod(
      "upload-azure-url",
      {
        filename: fname,
      }
    );

    const { data } = await axios.put(uploadUrl, fileObj, {
      headers: { "x-ms-blob-type": "BlockBlob", "content-type": "text/csv" },
    });

    console.log(data);

    if (error) throw new Error(error);
    return uploadUrl;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

/**
 *
 * @param {object} requestData
 * @param {string} requestData.oldPassword
 * @param {string} requestData.newPassword
 */
export async function changePasswordApi(requestData) {
  try {
    const { data, error } = await client.invokeMethod(
      "update-password",
      requestData
    );
    console.log([data, error]);
    if (error) throw new Error(error);
    return data;
  } catch (e) {
    console.warn(e);
    return null;
  }
}
