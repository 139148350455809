import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import { Box, useMediaQuery } from "@material-ui/core";
import { MenuItemLink } from "react-admin";
// sub-menu icon
import MasterProductIcon from "@material-ui/icons/Store";
import MasterVoucherIcon from "@material-ui/icons/RecentActors";
import MasterUtilityIcon from "@material-ui/icons/Settings";
import MasterLogIcon from "@material-ui/icons/Book";
// menu icon
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BusinessIcon from "@material-ui/icons/Business";
import CustomerIcon from "@material-ui/icons/ShoppingCart";
import UserIcon from "@material-ui/icons/Group";
import BroadcastLogIcon from "@material-ui/icons/Send";
import ProductCategoryIcon from "@material-ui/icons/Folder";
import ProductItemIcon from "@material-ui/icons/Work";
import TransactionIcon from "@material-ui/icons/TrendingUp";
import LanguageIcon from "@material-ui/icons/Language";
import VoucherCategoryIcon from "@material-ui/icons/Payment";
import VoucherStockIcon from "@material-ui/icons/SimCard";
import WrapTextIcon from "@material-ui/icons/WrapText";
import DocumentIcon from "@material-ui/icons/Description";
import SupplyMapIcon from "@material-ui/icons/Power";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
//
import DashboardIcon from "@material-ui/icons/Dashboard";

import SubMenu from "./subMenu";

const Menu = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuProducts: false,
    menuCustomers: false,
    menuDashboard: false,
    menuSupply: false,
    menuVouchers: false,
    menuUtilities: false,
    menuLogs: false,
    menuPromotions: false,
  });
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div>
      <Box pt={2} />
      {/* <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} /> */}
      <SubMenu
        handleToggle={() => handleToggle("menuDashboard")}
        isOpen={state.menuDashboard}
        sidebarIsOpen={open}
        name="Dashboard"
        icon={<DashboardIcon />}
      >
        <MenuItemLink
          to={`/dashboard`}
          primaryText="Dashboard"
          onClick={onMenuClick}
          leftIcon={<DashboardIcon />}
        />
        <MenuItemLink
          to={`/b2b_dashboard`}
          primaryText="B2B Dashboard"
          onClick={onMenuClick}
          leftIcon={<DashboardIcon />}
        />
      </SubMenu>
      <MenuItemLink
        to={`/users`}
        primaryText="Users"
        leftIcon={<UserIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle("menuCustomers")}
        isOpen={state.menuCustomers}
        sidebarIsOpen={open}
        name="Customers"
        icon={<CustomerIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/customers`}
          primaryText="End consumers (B2C)"
          leftIcon={<PeopleAltIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/customers-b2b`}
          primaryText="Business (B2B)"
          leftIcon={<BusinessIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      {/* <MenuItemLink
        to={`/customers`}
        primaryText="Customers"
        leftIcon={<CustomerIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      /> */}
      <MenuItemLink
        to={`/transactions`}
        primaryText="Transactions"
        leftIcon={<TransactionIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle("menuProducts")}
        isOpen={state.menuProducts}
        sidebarIsOpen={open}
        name="Products"
        icon={<MasterProductIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/product_categories`}
          primaryText="Categories"
          leftIcon={<ProductCategoryIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/product_items`}
          primaryText="Items"
          leftIcon={<ProductItemIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuPromotions")}
        isOpen={state.menuPromotions}
        sidebarIsOpen={open}
        name="Promotions"
        icon={<LoyaltyIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/promotions`}
          primaryText="Banner Promotions"
          leftIcon={<LoyaltyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/promotion_codes`}
          primaryText="Promotion Code"
          leftIcon={<LoyaltyIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuSupply")}
        isOpen={state.menuSupply}
        sidebarIsOpen={open}
        name="Supplies"
        icon={<SupplyMapIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/supply_maps`}
          primaryText="Item Matching"
          leftIcon={<SupplyMapIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/routing_rules`}
          primaryText="Routing"
          leftIcon={<SupplyMapIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuVouchers")}
        isOpen={state.menuVouchers}
        sidebarIsOpen={open}
        name="Vouchers"
        icon={<MasterVoucherIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/voucher_categories`}
          primaryText="Categories"
          leftIcon={<VoucherCategoryIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/voucher_stocks`}
          primaryText="Stocks"
          leftIcon={<VoucherStockIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuUtilities")}
        isOpen={state.menuUtilities}
        sidebarIsOpen={open}
        name="Utilities"
        icon={<MasterUtilityIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/documents`}
          primaryText="Batch"
          leftIcon={<DocumentIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/languages`}
          primaryText="Languages"
          leftIcon={<LanguageIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuLogs")}
        isOpen={state.menuLogs}
        sidebarIsOpen={open}
        name="Logs"
        icon={<MasterLogIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/broadcast_logs`}
          primaryText="Broadcasts"
          leftIcon={<BroadcastLogIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/execution_logs`}
          primaryText="Executions"
          leftIcon={<WrapTextIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText="Configuration"
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
