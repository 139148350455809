import Swal from "sweetalert2";
import { generateAuthenticatorCode } from "../provider/mbaasClient";

export async function resetAuthenticator() {
  const promptResult = await Swal.mixin({
    input: "password",
    confirmButtonText: "Continue &rarr;",
    showCancelButton: true,
    progressSteps: ["1", "2"],
  }).queue([
    {
      title: "Reset Authenticator",
      text: "Type your password",
    },
    {
      title: "Reset Authenticator",
      text: "Type your password again to confirm",
    },
  ]);

  // return if canceled
  if (!promptResult.value || promptResult.cancel) {
    return;
  }
  // warn if incorrect password
  if (promptResult.value[0] !== promptResult.value[1]) {
    Swal.fire("Failed", "Password missmatch", "warning");
    return;
  }

  // confirm button
  const isConfirmed = await Swal.fire({
    title: "Are you sure?",
    text:
      "By clicking 'YES', your existing configuration in google authenticator will no longer valid!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.value) return true;
    return false;
  });
  //
  if (!isConfirmed) return;

  const answer = promptResult.value[0];
  const authQr = await generateAuthenticatorCode(answer);
  console.log(authQr);

  if (authQr === null) {
    Swal.fire("Failed", "Invalid Credential. Please try again", "error");
  } else if (authQr.qr) {
    Swal.fire({
      text: "Please scan with your Google Authenticator App!",
      imageUrl: authQr.qr,
    });
  }
}
