import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { client } from "../provider/mbaasClient";

import { useNotify, Notification } from "react-admin";
import { useHistory } from "react-router-dom";

import google from "../assets/google.png";

export default () => {
  const [showLoading, setShowLoading] = useState(true);
  const [status, setStatus] = useState("Linking your Google account");
  const notify = useNotify();
  const history = useHistory();

  const thisUrl = new URL(window.location.href);
  const googleCode = thisUrl.searchParams.get("code");
  window.history.pushState(null, null, window.location.pathname);
  if (googleCode) {
    (async () => {
      const loginRes = await client.auth.login("google", {
        callback: window.location.origin + window.location.pathname,
        code: googleCode
      });
      // console.log(loginRes);
      if (loginRes.error) {
        notify(loginRes.error.title || loginRes.message);
        history.push("/");
      } else {
        const linkRes = await client.user.update("link", {
          provider: "google",
          oauthKey: loginRes.data.oauthKey
        });
        // console.log(linkRes);
        if (linkRes.error) {
          notify(loginRes.error.title || loginRes.message);
          history.push("/");
        } else {
          setShowLoading(false);
          setStatus("Google account linked succesfuly");
          setTimeout(() => history.push("/"), 3000);
        }
      }
    })();
  }

  return (
    <Card style={{ padding: "64px", marginTop: "16px" }}>
      <CardContent>
        <Grid container direction="column" justify="center" alignItems="center">
          <img src={google} alt="account link"></img>
          <h1>{status}</h1>
          {showLoading && <CircularProgress color="secondary" />}
        </Grid>
      </CardContent>
      <Notification />
    </Card>
  );
};
