import React from "react";
import { ListItemIcon, MenuItem, Typography } from "@material-ui/core";
import { AppBar, UserMenu, useAuthProvider } from "react-admin";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { manageProfile } from "../services/manageProfile";
import { makeStyles } from "@material-ui/core/styles";

const ManageProfile = () => {
  const authP = useAuthProvider();
  return (
    <MenuItem onClick={() => manageProfile(authP.data())}>
      <ListItemIcon style={{ minWidth: "40px" }}>
        <AccountBoxIcon />
      </ListItemIcon>
      Manage Profile
    </MenuItem>
  );
};

const MyUserMenu = props => (
  <UserMenu {...props}>
    <ManageProfile />
  </UserMenu>
);

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  spacer: {
    flex: 1
  }
});

const MyAppBar = props => {
  const classes = useStyles();
  const authP = useAuthProvider();

  return (
    <AppBar {...props} userMenu={<MyUserMenu />} elevation={1}>
      {" "}
      <Typography
        variant="h6"
        color="inherit"
        id="react-admin-title"
        className={classes.title}
      />
      <span className={classes.spacer} />
      <span>{authP.email()}</span>
    </AppBar>
  );
};
export default MyAppBar;
