import Swal from 'sweetalert2';
import { resetAuthenticator } from './resetAuthenticator';
import { changePasswordFlow } from './changePassword';
import { client } from '../provider/mbaasClient';

export async function manageProfile(promisedAuthData) {
  const authData = await promisedAuthData;
  const googleLinked = (authData.social_ids || {})['google'];

  const inputOptions = {
    changePassword: 'Change Password',
    resetAuthenticator: 'Reset authenticator'
  };
  if (!googleLinked) inputOptions['linkGoogle'] = 'Link Google Account';
  else inputOptions['unlinkGoogle'] = 'Unlink Google Account';

  const { value } = await Swal.fire({
    title: 'Manage your profile',
    text: authData.email,
    input: 'select',
    inputOptions,
    inputPlaceholder: '',
    showCancelButton: true
  });

  switch (value) {
    case 'changePassword':
      return changePasswordFlow();
    case 'resetAuthenticator':
      return resetAuthenticator();
    case 'linkGoogle':
      return linkGoogleAccount();
    case 'unlinkGoogle':
      return unlinkGoogleAccount();
    default:
  }
}

async function linkGoogleAccount() {
  client.auth.oauthRedirect('google', window.location.origin + '/account-link');
}

async function unlinkGoogleAccount() {
  Swal.queue([
    {
      title: 'Unlink current Google account',
      text: 'Please press YES to continue',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      preConfirm: () => {
        return client.user
          .update('unlink', { provider: 'google' })
          .then((resp) => {
            Swal.insertQueueStep(resp.message);
          })
          .catch(() => {
            Swal.insertQueueStep({
              icon: 'error',
              title: 'Unable to process your request'
            });
          });
      }
    }
  ]);
}
