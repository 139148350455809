import moment from "moment";
import gqltag from "graphql-tag";
import Axios from "axios";
import { moco, client, getDashboard, appURL } from "./mbaasClient";

export default {
  getList: async (resource, params) => {
    console.log(["getList", resource, params]);
    const { page, perPage } = params.pagination;
    let { field, order } = params.sort;
    const { dateGte, dateLte, latestStatus, textSearch } = params.filter || {};

    let tableInclude = [];

    if (field === "id") {
      field = "created_at";
      order = "DESC";
    }

    let filterObject = moco.composeFilter();

    // users ONLY
    if (resource === "users") {
      if (params.filter.q) {
        filterObject = moco
          .composeFilter()
          .contains((x) => x.toLower("name"), params.filter.q.toLowerCase());
      }
    }

    //languages ONLY
    if (resource === "languages") {
      if (params.filter.language) {
        filterObject = moco
          .composeFilter()
          .eq("language", params.filter.language);
      }
    }

    // transactions ONLY
    if (resource === "transactions") {
      // console.log(params.filter);
      tableInclude = ["customers"];
      filterObject = moco.composeFilter("or");
      // use OR with text search
      if (textSearch) {
        filterObject = filterObject
          .contains("target", textSearch)
          .contains((x) => x.toLower("order_id"), textSearch.toLowerCase());
      }
      // AND with others
      if (params.filter.q) {
        filterObject = filterObject.and((x) =>
          x.contains(
            (x) => x.toLower("order_id"),
            params.filter.q.toLowerCase()
          )
        );
      }
      if (params.filter.customer_id) {
        filterObject = filterObject.and((x) =>
          x.eq("customer_id", params.filter.customer_id)
        );
      }
      if (dateGte && dateLte) {
        filterObject = filterObject.and((x) =>
          x
            .ge("created_at", moment(dateGte).startOf("day").toISOString())
            .le("created_at", moment(dateLte).endOf("day").toISOString())
        );
      }
      if (latestStatus && latestStatus !== "ALL") {
        filterObject = filterObject.and((x) =>
          x.eq("latest_status", latestStatus)
        );
      }
    }
    // transaction_histories ONLY
    if (resource === "transaction_histories") {
      if (params.filter.transactionId) {
        filterObject = moco
          .composeFilter()
          .eq("transaction_id", params.filter.transactionId);
      }
    }
    // promotions_code ONLY
    if (resource === "promotions_code") {
      // console.log(params, 777);
    }
    // voucher_stocks ONLY
    if (resource === "voucher_stocks") {
      filterObject = moco.composeFilter();
      if (params.filter.voucher_category) {
        filterObject = filterObject.eq(
          "voucher_category",
          params.filter.voucher_category
        );
      }
      if (params.filter.status) {
        filterObject = filterObject.eq("status", params.filter.status);
      }
    }
    // customers ONLY
    if (resource === "customers-b2b") {
      console.log("hello");
      resource = "customers";
      filterObject = moco.composeFilter("or");
      filterObject = filterObject.ne("b2b_user", null);
    }
    if (resource === "customers") {
      tableInclude = ["members"];
      filterObject = moco.composeFilter("and");
      console.log(params.filter);
      if (params.filter.b2b_only) {
        filterObject = filterObject.ne("b2b_user", null);
      }
      if (params.filter.b2c_only) {
        filterObject = filterObject.eq("b2b_user", null);
      }
      if (dateGte && dateLte) {
        filterObject = filterObject.and((x) =>
          x
            .ge("created_at", moment(dateGte).startOf("day").toISOString())
            .le("created_at", moment(dateLte).endOf("day").toISOString())
        );
      }
      // if (params.filter.activeTab !== undefined) {
      //   if (params.filter.activeTab) {
      //     filterObject = moco.composeFilter().ne("b2b_user", null);
      //   } else {
      //     filterObject = moco.composeFilter().eq("b2b_user", null);
      //   }
      // }
      if (params.filter.channel) {
        filterObject = moco
          .composeFilter()
          .eq("channel", params.filter.channel);
      }
      if (params.filter.promoCodeChannel) {
        if (params.filter.promoCodeChannel === "ALL") {
          filterObject = moco
            .composeFilter()
            .ne("channel", params.filter.promoCodeChannel);
        } else {
          filterObject = moco
            .composeFilter()
            .eq("channel", params.filter.promoCodeChannel);
        }
      }
      //
      if (params.filter.q) {
        filterObject = filterObject.contains(
          (x) => x.toLower("name"),
          params.filter.q.toLowerCase()
        );
      }
      if (params.filter.memberStatus) {
        if (params.filter.memberStatus === "NOT YET APPLIED") {
          let tables = client.getTable("members");
          tables = tables.sort(
            order === "DESC" ? `-${"created_at"}` : "created_at"
          );
          const { data: datas, total: totals } = await tables.read();

          const customer = datas.map((dt) => {
            return dt.customer_id;
          });
          console.log("customer", customer);

          if (customer.length === 0) {
            return { data: customer, total: totals };
          }

          const allcustomerid = customer.join(" and id ne ");

          let filterS = "id ne ";
          filterS = filterS.concat(allcustomerid);

          // console.log(filterObject.toString());

          const queryResult = await client.gql.query({
            query: gqltag`
              query($filter: String,$sort: CustomersSortEnum,$order: PaginationOrderEnum,$limit: Int, $offset: Int) {
                allCustomersList(limit:$limit, filter:$filter, sort:$sort, order:$order, offset:$offset)
                {
                  data {
                    id
                    created_at
                    name
                    channel
                    channel_id
                    b2b_user
                    language
                    metadata
                  }
                  count
                }
              }
            `,
            variables: {
              // "filter": "id ne f9a4dd04-b375-481e-bbcf-77978a5e1812 and id ne b139c92b-fa62-4f02-82fc-64bccdf836a9 and id ne a789bcd3-acc3-4173-9d5c-a4f3c03b30d9 and id ne a789bcd3-acc3-4173-9d5c-a4f3c03b30d9",
              filter: filterS + " and " + filterObject.toString(),
              sort: field,
              order: order,
              limit: perPage,
              offset: (page - 1) * perPage,
            },
          });
          console.log("results", queryResult);
          return {
            data: queryResult.allCustomersList.data,
            total: queryResult.allCustomersList.count,
          };
        } else {
          const token = localStorage.getItem("MBaaS.auth.accessToken");

          const data = await Axios.get(`${appURL}/webhook/filter-membership`, {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              status: params.filter.memberStatus,
            },
          });

          console.log("data", data);

          const customer = data.data.map((dt) => {
            return dt.customer_id;
          });

          console.log(customer);

          if (customer.length === 0) {
            return { data: customer, total: customer.length };
          }

          filterObject = filterObject.in("id", customer);
        }
      }
    }

    // members ONLY
    if (resource === "members") {
      if (params.filter.customer_id) {
        filterObject = moco
          .composeFilter()
          .eq("customer_id", params.filter.customer_id);
      }
    }

    // execution_logs ONLY
    if (resource === "execution_logs") {
      filterObject = moco.composeFilter();
      // console.log(params.filter);
      if (params.filter.order_id) {
        filterObject = filterObject.eq("transaction", params.filter.order_id);
      }
    }
    // supply_maps ONLY
    if (resource === "supply_maps") {
      filterObject = moco.composeFilter();
      // console.log(params.filter);
      if (params.filter.product_id) {
        filterObject = filterObject.eq("product_id", params.filter.product_id);
      }
    }

    // pricings ONLY
    if (resource === "pricings") {
      filterObject = moco.composeFilter();
      tableInclude = ["product_items"];
      if (params.filter.sell_price) {
        filterObject = filterObject.le("price", params.filter.sell_price);
      }
      if (params.filter.channel_code) {
        filterObject = filterObject.eq(
          "channel_code",
          params.filter.channel_code
        );
      }
    }

    // product_items ONLY
    if (resource === "product_items") {
      filterObject = moco.composeFilter();
      console.log(params.filter);
      if (params.filter.category) {
        filterObject = filterObject.eq(
          "product_category",
          params.filter.category
        );
      }
      if (params.filter.q) {
        filterObject = filterObject.contains(
          (x) => x.toLower("name"),
          params.filter.q.toLowerCase()
        );
      }
      if (params.filter.qDescription) {
        filterObject = filterObject.contains(
          (x) => x.toLower("description"),
          params.filter.qDescription.toLowerCase()
        );
      }
      if (params.filter.pCountry && params.filter.ctx === "promoProduct") {
        const queryResult = await client.gql.query({
          query: gqltag`
            query($country: String, $name: String, $limit: Int)
            {
              allProductItemsList(limit: $limit, where: {name: {_ilike: $name} ,product_category_data:{country: { ${
                params.filter.pCountry === "ALL" ? "_neq" : "_eq"
              }: $country}}}) 
              {
                count
                data
                {
                  id
                  name
                  product_category_data 
                  {
                    category
                    country
                  }
                }
              }
            }
          `,
          variables: {
            // "filter": "id ne f9a4dd04-b375-481e-bbcf-77978a5e1812 and id ne b139c92b-fa62-4f02-82fc-64bccdf836a9 and id ne a789bcd3-acc3-4173-9d5c-a4f3c03b30d9 and id ne a789bcd3-acc3-4173-9d5c-a4f3c03b30d9",
            country: params.filter.pCountry,
            name:
              params.filter.q === undefined ? `% %` : `%${params.filter.q}%`,
            limit: 25,
          },
        });
        console.log("results", queryResult, 123);
        return {
          data: queryResult.allProductItemsList.data,
          total: queryResult.allProductItemsList.count,
        };
      }
    }

    if (resource === "product_categories") {
      filterObject = moco.composeFilter();
      console.log(params.filter);
      if (params.filter.q) {
        filterObject = filterObject.contains(
          (x) => x.toLower("category"),
          params.filter.q.toLowerCase()
        );
      }
      if (params.filter.country) {
        filterObject = filterObject.eq(
          (x) => x.toLower("country"),
          params.filter.country.toLowerCase()
        );
      }
      field = "category"; // sort by category field
    }

    // user_b2b ONLY
    if (resource === "users.b2b") {
      console.log("users b2b");
      resource = "users";
      filterObject = moco.composeFilter().ne("supplier_code", null);
    }

    // routing_rules ONLY
    if (resource === "routing_rules") {
      if (params.filter.product_category) {
        filterObject = moco
          .composeFilter()
          .eq("product_category", params.filter.product_category);
      }
    }

    if (resource === "deposit-history") {
      let { data, total } = await client
        .getTable("ledger_accounts")
        .filter(
          moco
            .composeFilter()
            .eq("customer_id", params.b2bId)
            .ne("credit", 0)
            .ne("metadata", null)
            .toString()
        )
        .sort(order === "DESC" ? `-${field}` : field)
        .read();

      console.log(`ledger`, data);

      console.log(
        `data.metadata`,
        data.map((data) => {
          return data.metadata.author;
        })
      );

      const authorId = data.map((data) => {
        return data.metadata.author;
      });

      const author = await client
        .getTable("users")
        .filter(moco.composeFilter().in("id", authorId).toString())
        .read();

      data = data
        .map((mappedData) => {
          return Object.assign({
            ...mappedData,
            author: author.data.filter(
              (filterAuthor) => filterAuthor.id === mappedData.metadata.author
            ),
          });
        })
        .slice((page - 1) * perPage, page * perPage);

      console.log(`data`, data);

      return { data: data, total: total };
    }

    // documents ONLY
    if (resource === "documents") {
      if (params.filter.type)
        filterObject = moco.composeFilter().eq("type", params.filter.type);
    }
    // console.log([dateGte, dateLte, filterObject.toString()]);

    // promotions ONLY
    if (resource === "promotions") {
      tableInclude = ["promotion_histories"];

      let table = client.getTable(resource);
      if (tableInclude.length !== null) {
        table = table.include(tableInclude);
      }

      if (filterObject !== "") filterObject = filterObject.toString();
      if (params.id) {
        table = table
          .filter(moco.composeFilter().eq("id", params.id).toString())
          .sort("-created_at")
          .limit(100)
          .offset((page - 1) * perPage);
      } else {
        table = table
          .filter(filterObject || "true")
          // .sort(order === "DESC" ? `-${field}` : field)
          // .sort('-active')
          // .sort('-active, priority_position')
          .limit(100)
          .offset((page - 1) * perPage);
      }
      const { data, total, included } = await table.read();

      const imageUrl = data.map(async (el) => {
        const getUrl = async () => {
          let preview = await client.storage.downloadUrl({
            fileId: el.cover_image,
            params: {
              bucket: "promotions",
            },
          });
          return preview.data.url;
        };

        return {
          ...el,
          urlCover: await getUrl(),
        };
        // console.log(preview, 88);
      });
      const results = await Promise.all(imageUrl);

      // console.log(results, 5758);
      // console.log("data", data);

      return { data: results, total: total, included: included };
    }

    if (resource === "promotion_histories") {
      let table = client.getTable(resource);
      if (tableInclude.length !== 0) {
        table = table.include(tableInclude);
      }
      table = table
        .filter(moco.composeFilter().eq("promotion_id", params.id).toString())
        .sort("-created_at")
        .limit(100)
        .offset(0);
      const { data, total } = await table.read();
      // console.log("data", data);

      const imageUrl = data.map(async (el) => {
        const getUrl = async () => {
          let preview = await client.storage.downloadUrl({
            fileId: el.cover_image,
            params: {
              bucket: "promotions",
            },
          });
          return preview.data.url;
        };

        return {
          ...el,
          urlCover: await getUrl(),
        };
        // console.log(preview, 88);
      });
      const results = await Promise.all(imageUrl);
      // console.log(results, 123);
      return { data: results };
    }

    if (filterObject !== "") filterObject = filterObject.toString();
    let table = client.getTable(resource);
    if (tableInclude.length !== 0) {
      table = table.include(tableInclude);
    }
    table = table
      .filter(filterObject || "true")
      .sort(order === "DESC" ? `-${field}` : field)
      .limit(perPage)
      .offset((page - 1) * perPage);
    const { data, total } = await table.read();
    console.log("data", data);

    return { data: data, total: total };
  },

  getOne: async (resource, params) => {
    console.log("getOne", resource, params);
    let tableInclude = [];
    if (resource === "promotions") {
      let tables = client.getTable(resource);
      if (params.include) {
        tables = tables.include(params.include);
        const { data, included } = await tables.lookup(params.id);

        let preview = await client.storage.downloadUrl({
          fileId: data.cover_image,
          params: {
            bucket: "promotions",
          },
        });

        if (
          data.channel_location.includes("Line") &&
          data.channel_location.includes("Facebook")
        ) {
          data.channel_location = "All";
        }

        const imageUrl = data.promotion_histories.map(async (el) => {
          const getUrl = async () => {
            let preview = await client.storage.downloadUrl({
              fileId: el.cover_image,
              params: {
                bucket: "promotions",
              },
            });
            return preview.data.url;
          };

          return {
            ...el,
            urlCover: await getUrl(),
          };
          // console.log(preview, 88);
        });
        const results = await Promise.all(imageUrl);

        data.urlCover = preview.data.url;
        console.log(data);
        return { data: data, histories: results };
      } else {
        const { data } = await tables.lookup(params.id);

        let preview = await client.storage.downloadUrl({
          fileId: data.cover_image,
          params: {
            bucket: "promotions",
          },
        });

        if (
          data.channel_location.includes("Line") &&
          data.channel_location.includes("Facebook")
        ) {
          data.channel_location = "All";
        }

        data.urlCover = preview.data.url;
        console.log(data);
        return { data: data };
      }
    }
    if (resource === "customers-b2b") {
      resource = "customers";
    }
    if (resource === "dashboard") {
      const data = await getDashboard();
      return { data };
    }
    if (resource === "transactions") {
      tableInclude = ["customers"];
    }

    let table = client.getTable(resource);
    if (tableInclude.length !== 0) {
      table = table.include(tableInclude);
    }

    const { data } = await table.lookup(params.id);
    // console.log(data);
    return { data: data };
  },

  getMany: async (resource, params) => {
    console.log(["getMany", resource, params]);
    const totalIds = params.ids ? params.ids.length : null;
    const { data } = await client
      .getTable(resource)
      .filter(moco.composeFilter().in("id", params.ids).toString())
      .limit(totalIds || 10)
      .read();
    return { data: data };
  },

  getManyReference: async (resource, params) => {
    console.log(["getManyReference", resource, params]);
    let { field, order } = params.sort || {
      field: "created_at",
      order: "DESC",
    };
    let filterInstance = null;

    if (resource === "ledger_accounts") {
      filterInstance = moco
        .composeFilter()
        .eq(params.target, params.id)
        .ne("ending_balance", null)
        .toString();
    } else {
      filterInstance = moco
        .composeFilter()
        .eq(params.target, params.id)
        .toString();
    }

    const { data, total } = await client
      .getTable(resource)
      .filter(filterInstance)
      .sort(order === "DESC" ? `-${field}` : field)
      .limit(params.pagination.perPage)
      .read();
    return { data: data, total };
  },

  update: async (resource, params) => {
    console.log("update", { resource, params });
    if (resource === "users") {
      [
        "id",
        "password",
        "type",
        "allowed_ip",
        "deactivated_at",
        "email",
        "fcm_tokens",
        "password",
        "social_ids",
        "supplier_status",
        "totp_secret",
      ].forEach((paramData) => {
        delete params.data[paramData];
      });
    }
    if (resource === "customers-b2b") {
      [
        "id",
        "type",
        "b2b_user",
        "channel",
        "channel_id",
        "language",
        "latest_transaction_timestamp",
        "name",
        "photo",
      ].forEach((paramData) => {
        delete params.data[paramData];
      });
      resource = "customers";
    }
    if (
      resource === "product_categories" ||
      resource === "product_items" ||
      resource === "supply_maps" ||
      resource === "voucher_categories" ||
      resource === "languages"
    ) {
      ["id", "type"].forEach((paramData) => {
        delete params.data[paramData];
      });
    }
    if (resource === "voucher_stocks") {
      ["id", "type", "used_at"].forEach((paramData) => {
        delete params.data[paramData];
      });
    }

    // promotions_code ONLY
    if (resource === "promotion_codes") {
      const {
        promo_code,
        channel_loc,
        method,
        value,
        price_limit,
        product_limit_type,
        cCountry,
        pCountry,
        specific_category,
        specific_product,
        limit_user_type,
        specific_customer,
        max_usage_period,
        max_usage_customer,
        start_period,
        end_period,
        dailyOptions,
        max_usage_daily,
        start_hour,
        end_hour,
        is_enabled,
      } = params.data;

      let country = null;
      let sProduct = null;
      let sCategory = null;

      if (product_limit_type === "ALL") {
        country = null;
      } else if (product_limit_type === "CATEGORY") {
        sCategory = specific_category;
        country = cCountry;
      } else if (product_limit_type === "PRODUCT") {
        sProduct = specific_product;
        country = pCountry;
      }

      let startHour = "";
      let endHour = "";
      let maxUsageDaily = 0;

      if (dailyOptions) {
        start_hour === undefined
          ? (startHour = moment("00:00:00", "HH:mm:ss").format("HH:mm:ss"))
          : (startHour = moment(start_hour, "HH:mm:ss").format("HH:mm:ss"));
        end_hour === undefined
          ? (endHour = moment("23:59:59", "HH:mm:ss").format("HH:mm:ss"))
          : (endHour = moment(end_hour, "HH:mm:ss").format("HH:mm:ss"));
        maxUsageDaily = max_usage_daily;
        console.log(start_hour, end_hour, 666);
      } else {
        startHour = moment("00:00:00", "HH:mm:ss").format("HH:mm:ss");
        endHour = moment("23:59:59", "HH:mm:ss").format("HH:mm:ss");
        maxUsageDaily = null;
      }

      const filteredData = Object.assign({
        promo_code: promo_code,
        channel: channel_loc === "ALL" ? ["LINE", "FACEBOOK"] : [channel_loc],
        method: method,
        value: value,
        price_limit: price_limit || 0,
        product_limit_type: product_limit_type,
        product_country: country,
        specific_category: sCategory,
        specific_product: sProduct,
        specific_customer: limit_user_type === "ALL" ? null : specific_customer,
        max_usage_period: max_usage_period,
        max_usage_customer: max_usage_customer,
        start_period: moment(start_period).format("YYYY-MM-DD"),
        end_period: moment(end_period).format("YYYY-MM-DD"),
        max_usage_daily: maxUsageDaily,
        start_hour: startHour,
        end_hour: endHour,
        is_enabled: is_enabled,
      });

      const { data } = await client.getTable(resource).update(
        Object.assign({
          id: params.id,
          data: filteredData,
        })
      );
      return { data: data };
      // return console.log(params, 777);
    }

    if (resource === "promotions") {
      if (params.contex && params.contex === "dnd") {
        const { priority_position, active, click_count } = params.data;
        // console.log(priority_position, 456);
        const filteredData = Object.assign({
          id: params.id,
          priority_position: active ? priority_position : [null, null],
          active: active,
          click_count: click_count,
        });

        // const { data } = await client.getTable(resource).update(
        //   Object.assign({
        //     id: params.id,
        //     data: filteredData,
        //   })
        // );

        const { data } = await client.invokeApi(
          "promotion-update",
          filteredData
        );

        return { data: data };
      } else {
        const {
          lineActiveCount,
          messengerActiveCount,
          title,
          channel_location,
          linePosition,
          messengerPosition,
          begin_date,
          end_date,
          description,
          active,
          pictures,
          click_count,
        } = params.data;

        // console.log(linePosition, messengerPosition, 666);

        if (pictures) {
          const uploadCoverPicture = await client.storage.upload({
            file: pictures.rawFile,
            params: {
              filename: `banner-${moment(new Date()).format()}`,
              bucket: "promotions",
            },
          });

          let priority;

          if (active) {
            if (channel_location === "Line") {
              if (linePosition) {
                priority = [linePosition.toString(), null];
              } else {
                priority = [(parseInt(lineActiveCount) + 1).toString(), null];
              }
            } else if (channel_location === "Facebook") {
              if (messengerPosition) {
                priority = [null, messengerPosition.toString()];
              } else {
                priority = [
                  null,
                  (parseInt(messengerActiveCount) + 1).toString(),
                ];
              }
            } else if (channel_location === "All") {
              if (linePosition) {
                priority = [
                  linePosition.toString(),
                  (parseInt(messengerActiveCount) + 1).toString(),
                ];
              } else if (messengerPosition) {
                priority = [
                  (parseInt(lineActiveCount) + 1).toString(),
                  messengerPosition.toString(),
                ];
              } else if (linePosition && messengerPosition) {
                priority = [
                  linePosition.toString(),
                  messengerPosition.toString(),
                ];
              }
            }
          } else {
            priority = [null, null];
          }

          const filteredData = Object.assign({
            id: params.id,
            title: title ? title : "",
            active: active,
            click_count: 0,
            description: description ? description : "",
            priority_position: priority,
            cover_image: uploadCoverPicture.data.id,
            channel_location:
              channel_location === "All"
                ? ["Line", "Facebook"]
                : [`${channel_location}`],
            begin_date: moment(begin_date).format(),
            end_date: moment(end_date).format(),
          });

          // const { data } = await client.getTable(resource).update(
          //   Object.assign({
          //     id: params.id,
          //     data: filteredData,
          //   })
          // );

          const { data } = await client.invokeApi(
            "promotion-update",
            filteredData
          );

          return { data: data };
        } else {
          let priority;

          if (active) {
            if (channel_location === "Line") {
              if (linePosition) {
                priority = [linePosition.toString(), null];
              } else {
                priority = [(parseInt(lineActiveCount) + 1).toString(), null];
              }
            } else if (channel_location === "Facebook") {
              if (messengerPosition) {
                priority = [null, messengerPosition.toString()];
              } else {
                priority = [
                  null,
                  (parseInt(messengerActiveCount) + 1).toString(),
                ];
              }
            } else if (channel_location === "All") {
              if (linePosition) {
                priority = [
                  linePosition.toString(),
                  (parseInt(messengerActiveCount) + 1).toString(),
                ];
              } else if (messengerPosition) {
                priority = [
                  (parseInt(lineActiveCount) + 1).toString(),
                  messengerPosition.toString(),
                ];
              } else if (linePosition && messengerPosition) {
                priority = [
                  linePosition.toString(),
                  messengerPosition.toString(),
                ];
              }
            }
          } else {
            priority = [null, null];
          }

          const filteredData = Object.assign({
            id: params.id,
            priority_position: priority,
            title: title ? title : "",
            active: active,
            description: description ? description : "",
            channel_location:
              channel_location === "All"
                ? ["Line", "Facebook"]
                : [`${channel_location}`],
            begin_date: moment(begin_date).format(),
            end_date: moment(end_date).format(),
            click_count: click_count,
          });

          // const { data } = await client.getTable(resource).update(
          //   Object.assign({
          //     id: params.id,
          //     data: filteredData,
          //   })
          // );

          const { data } = await client.invokeApi(
            "promotion-update",
            filteredData
          );

          return { data: data };
        }
      }
      // return console.log("DATA", params.data);
    }
    // remove jsonb data
    delete params.data["created_at"];
    delete params.data["updated_at"];

    if (resource !== "product_categories") {
      delete params.data["metadata"];
    }
    // delete params.data["supply_reference"];
    // delete params.data["definition"];
    //
    const filteredData = Object.assign({ ...params.data });
    const { data } = await client.getTable(resource).update(
      Object.assign({
        id: params.id,
        data: filteredData,
      })
    );
    return { data: data };
  },

  create: async (resource, params) => {
    console.log("create", { resource, params });

    // promotions_code ONLY
    if (resource === "promotion_codes") {
      const {
        promo_code,
        channel,
        method,
        value,
        price_limit,
        product_limit_type,
        cCountry,
        pCountry,
        specific_category,
        specific_product,
        limit_user_type,
        specific_customer,
        max_usage_period,
        max_usage_customer,
        start_period,
        end_period,
        dailyOptions,
        max_usage_daily,
        start_hour,
        end_hour,
        is_enabled,
      } = params.data;

      let country = "";
      let sProduct = null;
      let sCategory = null;

      if (product_limit_type === "ALL") {
        country = null;
      } else if (product_limit_type === "CATEGORY") {
        sCategory = specific_category;
        country = cCountry;
      } else if (product_limit_type === "PRODUCT") {
        sProduct = specific_product;
        country = pCountry;
      }

      let startHour = "";
      let endHour = "";
      let maxUsageDaily = null;

      if (dailyOptions) {
        start_hour === undefined
          ? (startHour = moment("00:00:00", "HH:mm:ss").format("HH:mm:ss"))
          : (startHour = moment(start_hour, "HH:mm:ss").format("HH:mm:ss"));
        end_hour === undefined
          ? (endHour = moment("23:59:59", "HH:mm:ss").format("HH:mm:ss"))
          : (endHour = moment(end_hour, "HH:mm:ss").format("HH:mm:ss"));
        maxUsageDaily = max_usage_daily;
        console.log(start_hour, end_hour, 666);
      } else {
        startHour = moment("00:00:00", "HH:mm:ss").format("HH:mm:ss");
        endHour = moment("23:59:59", "HH:mm:ss").format("HH:mm:ss");
        maxUsageDaily = null;
      }

      const filteredData = Object.assign({
        promo_code: promo_code,
        channel: channel === "ALL" ? ["LINE", "FACEBOOK"] : [channel],
        method: method,
        value: value,
        price_limit: price_limit || 0,
        product_limit_type: product_limit_type,
        product_country: country,
        specific_category: sCategory,
        specific_product: sProduct,
        specific_customer: limit_user_type === "ALL" ? null : specific_customer,
        max_usage_period: max_usage_period,
        max_usage_customer: max_usage_customer,
        start_period: moment(start_period).format("YYYY-MM-DD"),
        end_period: moment(end_period).format("YYYY-MM-DD"),
        max_usage_daily: maxUsageDaily,
        start_hour: startHour,
        end_hour: endHour,
        is_enabled: is_enabled,
      });

      const { data } = await client.getTable(resource).insert(filteredData);
      return { data: data };
      // return console.log(params, 777);
    }

    if (resource === "promotions") {
      const {
        lineActiveCount,
        messengerActiveCount,
        title,
        channel_location,
        begin_date,
        end_date,
        description,
        active,
        pictures,
      } = params.data;
      let priority;

      if (active) {
        if (channel_location === "Line") {
          priority = [(parseInt(lineActiveCount) + 1).toString(), null];
        } else if (channel_location === "Facebook") {
          priority = [null, (parseInt(messengerActiveCount) + 1).toString()];
        } else if (channel_location === "All") {
          priority = [
            (parseInt(lineActiveCount) + 1).toString(),
            (parseInt(messengerActiveCount) + 1).toString(),
          ];
        }
      } else {
        priority = [null, null];
      }
      // return console.log(params);
      const uploadCoverPicture = await client.storage.upload({
        file: pictures.rawFile,
        params: {
          filename: `banner-${moment(new Date()).format()}`,
          bucket: "promotions",
        },
      });

      const filteredData = Object.assign({
        title: title,
        active: active,
        click_count: 0,
        description: description,
        priority_position: priority,
        cover_image: uploadCoverPicture.data.id,
        channel_location:
          channel_location === "All"
            ? ["Line", "Facebook"]
            : [`${channel_location}`],
        begin_date: moment(begin_date).format(),
        end_date: moment(end_date).format(),
      });
      // delete filteredData["logo"];
      // console.log({ resource, filteredData });
      const { data } = await client.getTable(resource).insert(filteredData);
      return { data: data };
      // return console.log("dataForSubmit", filteredData, pictures);
    }

    if (resource === "customers-b2b") {
      resource = "customers";
    }

    if (resource === "confirm-batch") {
      const result = await client.invokeApi("confirm-batch-transaction", {
        token: params.data.token,
        documentId: params.data.documentId,
        buyerChannel: params.data.buyerChannel,
        customerId: params.data.customerId,
      });
      console.log("data confirm batch", result);
      return { data: result };
    }

    if (resource === "batch-transactions") {
      const file = params.data.files;
      console.log("file", file);

      const convertFileToText = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsText(file.rawFile);

          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        });

      const newFile = await convertFileToText(file);

      const uploadUrl = await client.invokeApi("batch-transaction-upload", {
        fileName: file.rawFile.name,
      });

      console.log(`uploadUrl`, uploadUrl);

      if (uploadUrl.data.linkUpload) {
        const uploadFile = await Axios.put(uploadUrl.data.linkUpload, newFile);

        console.log(`uploadFile`, uploadFile);

        const user = await client.user.get();

        const insertDocument = await client.getTable("documents").insert({
          type: "TRXBATCH",
          filename: uploadUrl.data.fileName,
          created_by: user.data.id,
        });

        const { data } = await client
          .getTable("documents")
          .lookup(insertDocument.data.id);

        console.log("data", data);
      }

      return { data: uploadUrl };
    }

    if (resource === "inject-deposit") {
      const { slip, b2b_id, amount, remark, token } = params.data;

      const uploadFile = await client.storage.upload({
        params: {
          filename: slip.rawFile.name,
          bucket: "b2b-customers",
          signed: true,
        },
        file: slip.rawFile,
      });

      const inject = await client.invokeApi("b2b-portal-deposit-inject", {
        b2bId: b2b_id,
        amount: amount,
        remark: remark,
        token: token,
        bankSlip: uploadFile.data.id,
      });

      return { data: inject };
    }

    if (resource === "users") {
      const { name, email, password } = params.data;
      const regData = await client.auth.register("local", {
        email: email,
        password: password,
        extras: {
          name: name,
        },
      });
      //
      return { data: regData };
    }

    if (resource === "broadcast_logs") {
      const userData = await client.user.get();
      const { message, customer_id, recipient_list, channel } = params.data;
      const formattedData = Object.assign({
        message: message,
        target: {
          mode: recipient_list,
          customers: customer_id,
          channel: channel,
        },
        created_by: userData.data.id,
      });
      const { data } = await client.getTable(resource).insert(formattedData);
      return { data: data };
    } else {
      // remove jsonb data
      // delete params.data["supply_reference"];
      // delete params.data["definition"];
      //
      const filteredData = Object.assign({ ...params.data });
      delete filteredData["logo"];
      console.log({ resource, filteredData });
      const { data } = await client.getTable(resource).insert(filteredData);
      return { data: data };
    }
  },

  delete: async (resource, params) => {
    if (resource === "customers-b2b") {
      resource = "customers";
    }
    await client.getTable(resource).del({ id: params.id });
    return { data: params };
  },
};
