import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    display: "inline-block",
    width: "100%",
    textAlign: "center",
    marginTop: "300px"
  }
});

export default function LoadingIndicator() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress disableShrink />
    </div>
  );
}
