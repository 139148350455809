import React, { lazy } from "react";
import { Route } from "react-router-dom";
import AccountLink from "./components/AccountLink";

const dashboard = lazy(() => import("./components/dashboard"));
const b2b_dashboard = lazy(() => import("./components/b2b_dashboard"));

export default [
  <Route exact path="/account-link" component={AccountLink} noLayout />,
  <Route exact path="/dashboard" component={dashboard} />,
  <Route exact path="/b2b_dashboard" component={b2b_dashboard} />,
];
